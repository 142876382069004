<k5next-dialog-heading heading="Suche speichern"></k5next-dialog-heading>

<k5next-dialog-content>
    <mat-radio-group class="flex flex-col flex-wrap" (change)="radioSelectionChanged($event)">
        <mat-radio-button
            [value]="0"
            [checked]="saveExistingSearchSelected"
            *ngIf="data.searchData?.suche && data.searchData?.suche.istEigeneSuche"
        >
            bestehende Liste speichern
        </mat-radio-button>
        <mat-radio-button [value]="1" class="mt-4" [checked]="saveNewSeachSelected">
            als neue Liste speichern
        </mat-radio-button>
    </mat-radio-group>

    <form class="flex flex-col flex-wrap" [formGroup]="saveSearchForm">
        <mat-form-field class="basis-full">
            <mat-label>Name</mat-label>
            <input
                #name
                formControlName="name"
                matInput
                autocomplete="off"
                cdkFocusInitial
                data-cy="detailsuche-speichern-dialog-input-name"
            />
            <mat-hint>{{ name.value.length }} / 100</mat-hint>
            <mat-error
                *ngFor="
                    let error of formErrorMessageService.getFormControlErrorMessages(saveSearchForm.get('name'), 'Name')
                "
            >
                {{ error }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="basis-full">
            <mat-label>Beschreibung</mat-label>
            <textarea #beschreibung formControlName="beschreibung" matInput autocomplete="off"></textarea>
            <mat-hint>{{ beschreibung.value.length }} / 500</mat-hint>
            <mat-error
                *ngFor="
                    let error of formErrorMessageService.getFormControlErrorMessages(
                        saveSearchForm.get('beschreibung'),
                        'Beschreibung'
                    )
                "
            >
                {{ error }}
            </mat-error>
        </mat-form-field>
    </form>
</k5next-dialog-content>

<k5next-dialog-actions [alignEnd]="true">
    <button mat-stroked-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="save()" data-cy="detailsuche-speichern-dialog-button-speichern">
        Speichern
    </button>
</k5next-dialog-actions>
