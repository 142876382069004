// @ts-strict-ignore
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UiConstants } from '@core/constants/ui-constants';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { UserInformationService } from '@core/services/user-information.service';
import { KontaktmanagementDetailsuche, LoggingConstants } from '@shared/constants/shared-constants';
import { ChangeGespeicherteSucheRequest } from '@shared/models/changeGespeicherteSucheRequest';
import { CreateGespeicherteSucheRequest } from '@shared/models/createGespeicherteSucheRequest';
import { DetailsucheAdditionalSearchCriteria } from '@shared/models/detailsucheAdditionalSearchCriteria';
import { DetailsuchkriterienDaten } from '@shared/models/detailsuchkriterienDaten';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { JaNeinEnum } from '@shared/models/jaNeinEnum';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { Personenart } from '@shared/models/personenart';
import { UIDetailsucheRequest } from '@shared/models/uIDetailsucheRequest';
import { UITeilenMitBenutzer } from '@shared/models/uiTeilenMitBenutzer';
import { CapabilitiesService } from '@shared/services/capabilities.service';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { GruppenService } from '@shared/services/gruppen.service';
import { LoggingService } from '@shared/services/logging.service';
import { SchnellsucheService } from '@shared/services/schnellsuche.service';
import { Observable, Subscription, catchError, map, of, startWith, switchMap } from 'rxjs';
import { TagList } from 'src/app/buergerservice/kontaktmanagement/models/tags';
import { KontaktmanagementService } from 'src/app/buergerservice/kontaktmanagement/services/kontaktmanagement.service';
import { UserShareDialogComponent, UserShareDialogData } from '../user-share-dialog/user-share-dialog.component';
@Component({
    selector: 'k5-detail-search-kontakte',
    templateUrl: './detail-search-kontakte.component.html'
})
export class DetailSearchKontakteComponent implements OnInit, OnDestroy {
    title = 'Kontakte suchen';
    additionalSearchCriteria: DetailsucheAdditionalSearchCriteria[] = [
        {
            name: 'Registergeprüft',
            key: KontaktmanagementDetailsuche.IST_REGISTERGEBUNDEN,
            selected: false,
            display: true
        },
        {
            name: 'Notiz',
            key: KontaktmanagementDetailsuche.NOTIZ,
            selected: false,
            display: false
        },
        {
            name: 'Firmenbuchnummer',
            key: KontaktmanagementDetailsuche.FIRMENBUCH_NR,
            selected: false,
            display: true
        },
        {
            name: 'UID-Nummer',
            key: KontaktmanagementDetailsuche.UID_NUMMER,
            selected: false,
            display: true
        },
        {
            name: 'Ansprechpartner Funktion',
            key: KontaktmanagementDetailsuche.ANSPRECHPARTNER_FUNKTION,
            selected: false,
            display: true
        },
        {
            name: 'Postleitzahl',
            key: KontaktmanagementDetailsuche.POSTLEITZAHL,
            selected: false,
            display: true
        },

        {
            name: 'Gemeindekennziffer',
            key: KontaktmanagementDetailsuche.GKZ,
            selected: false,
            display: true
        },
        {
            name: 'Staat',
            key: KontaktmanagementDetailsuche.STAAT,
            selected: false,
            display: true
        },
        {
            name: 'Tag',
            key: KontaktmanagementDetailsuche.TAG,
            selected: false,
            display: false
        },
        {
            name: 'Gruppe',
            key: KontaktmanagementDetailsuche.GRUPPE,
            selected: false,
            display: false
        }
    ];

    PERSONENART_VALUES = Personenart;
    JA_NEIN_VALUES = JaNeinEnum;

    pageSize = 50;

    subscription = new Subscription();

    searchCriteriaForm = new FormGroup({
        art: new FormControl(Personenart.Alle),
        istRegistergebunden: new FormControl(JaNeinEnum.Alle),
        notiz: new FormControl(''),
        // Kontakt
        name: new FormControl(''),
        geburtsdatum: new FormControl(''),
        erreichbarkeit: new FormControl(''),
        gruppe: new FormControl(''),
        firmenbuchNr: new FormControl(''),
        uidNummer: new FormControl(''),
        ansprFunktion: new FormControl(''),
        tag: new FormControl(''),
        // Adresse
        strasse: new FormControl(''),
        orientierungsnummer: new FormControl(''),
        ort: new FormControl(''),
        postleitzahl: new FormControl(''),
        gemeindekennziffer: new FormControl(''),
        staatISO3: new FormControl('')
    });

    gespeicherteSuchen: KontaktmanagementClient.GespeicherteSucheResponse[] = [];

    selectedSearch: KontaktmanagementClient.GespeicherteSucheResponse | null = null;
    changedSearchData: KontaktmanagementClient.GespeicherteSucheResponse | null = null;

    currentParams: ParamMap;

    detailsucheData: UIDetailsucheRequest;

    generalCapabilities: GeneralCapabilities;

    gruppen: KontaktmanagementClient.GruppeResponse[];

    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredTags: Observable<string[]>;
    tags: string[] = [];
    allTagsAutocomplete: string[] = [];
    allTags: string[] = [];
    currentTagInput: string;

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    /**
     * Liste der vom Benutzer gespeicherten Suchkriterien
     */
    savedUserSearchCritera: DetailsucheAdditionalSearchCriteria[] | null = null;

    constructor(
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private globalEditService: GlobalEditService,
        private schnellsucheService: SchnellsucheService,
        private detailSucheService: DetailsucheService,
        private kontaktmanagementService: KontaktmanagementService,
        private loggingService: LoggingService,
        private capabilitiesService: CapabilitiesService,
        private gruppenService: GruppenService,
        private userInformationService: UserInformationService,
        private rightSideNavigationService: RightSidenavigationService
    ) {}

    /**
     * Beendet laufende Subscriptions
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.filteredTags = this.searchCriteriaForm.controls.tag.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => {
                this.currentTagInput = tag;
                let filteredList: string[];
                if (tag) {
                    filteredList = this.filterTagList(tag);
                } else {
                    filteredList = this.allTagsAutocomplete.slice();
                }

                return filteredList;
            })
        );

        // Lädt die Daten der Detailsuche neu, wenn diese wieder geöffnet wird
        this.subscription.add(
            this.rightSideNavigationService.rightSidenavOpen$.subscribe({
                next: (open: boolean) => {
                    if (open) {
                        this.loadDetailSearchData();
                    }
                }
            })
        );
    }

    /**
     * Setzt die Anzeige eines weiteren Suchkriteriums
     * @param searchCriteria Suchkriterium
     * @param key Key
     * @param display Ob das Suchkriterium angezeigt werden soll
     */
    setDisplayOfSearchCritera(
        searchCriteria: DetailsucheAdditionalSearchCriteria[],
        key: string,
        display: boolean
    ): void {
        const foundSearchCriteria = searchCriteria?.find((criteria) => criteria.key === key);
        if (foundSearchCriteria) {
            foundSearchCriteria.display = display;
        }
    }

    /**
     * Lädt alle Daten, die für die Detailsuche notwendig sind
     */
    loadDetailSearchData(): void {
        this.kontaktmanagementService
            .getTags()
            .pipe(
                switchMap((tags: TagList): Observable<DetailsucheAdditionalSearchCriteria[] | null> => {
                    // Setzt die Tags für die Auswahl in der Selekt Box der Detailsuche
                    this.allTagsAutocomplete = tags ? tags.tags : [];
                    this.allTags = tags ? tags.tags : [];
                    return this.userInformationService
                        .getBenutzerEinstellungFromService<
                            DetailsucheAdditionalSearchCriteria[]
                        >(UiConstants.KONTAKT_SEARCH_CRITERIA)
                        .pipe(
                            catchError(() => {
                                return of(null);
                            })
                        );
                }),
                switchMap(
                    (searchCriteria: DetailsucheAdditionalSearchCriteria[] | null): Observable<GeneralCapabilities> => {
                        // Setzt die vom User gespeicherten Suchkriterien, welche eingeblendet werden können
                        this.savedUserSearchCritera = searchCriteria;
                        return this.capabilitiesService.generalCapabilities$;
                    }
                ),
                switchMap(
                    (
                        capabilities: GeneralCapabilities
                    ): Observable<KontaktmanagementClient.GruppeResponse[] | null> => {
                        // Setzt die generellen Capabilities und blendet anhand dieser die weiteren Suchkriterien ein oder aus
                        this.generalCapabilities = capabilities;

                        if (this.generalCapabilities) {
                            this.setSearchCriteriaWithCapabilities(
                                this.generalCapabilities,
                                this.additionalSearchCriteria
                            );

                            if (this.savedUserSearchCritera?.length > 0) {
                                this.setSearchCriteriaWithCapabilities(
                                    this.generalCapabilities,
                                    this.savedUserSearchCritera
                                );
                            }

                            if (this.generalCapabilities.gruppe.canRead) {
                                return this.gruppenService.getGruppen().pipe(
                                    catchError(() => {
                                        return of(null);
                                    })
                                );
                            }
                        }
                        return of(null);
                    }
                ),
                switchMap(
                    (
                        gruppen: KontaktmanagementClient.GruppeResponse[]
                    ): Observable<KontaktmanagementClient.GespeicherteSuchenResponse | null> => {
                        // Setzt die Gruppen, welche in der Select Box der Detailsuche sichtbar sind
                        this.gruppen = gruppen;
                        if (this.generalCapabilities?.gespeicherteSuche.canRead) {
                            return this.kontaktmanagementService.getSuchen().pipe(
                                catchError(() => {
                                    return of(null);
                                })
                            );
                        }
                        return of(null);
                    }
                ),
                switchMap(
                    (
                        gespeicherteSuchen: KontaktmanagementClient.GespeicherteSuchenResponse | null
                    ): Observable<ParamMap> => {
                        // Setzt die gespeicherten Suchen, welche in der Select Box der Detailsuche sichtbar sind
                        if (gespeicherteSuchen) {
                            this.gespeicherteSuchen = gespeicherteSuchen.responses;
                        }

                        if (this.generalCapabilities?.gespeicherteSuche?.canRead) {
                            return this.route.queryParamMap.pipe(
                                catchError(() => {
                                    return of(null);
                                })
                            );
                        }
                        return of(null);
                    }
                )
            )
            .subscribe({
                next: (params: ParamMap | null) => {
                    if (params) {
                        this.currentParams = params;
                        // Lest die Parameter aus der URL aus und initialisiert die Detailsuche

                        // Wenn die Parameter den Search Key beinhalten, wurde eine normale Suche durchgeführt
                        if (params.has(KontaktmanagementDetailsuche.SEARCH_KEY)) {
                            this.detailsucheData = this.detailSucheService.getSearchFromStorage(
                                params.get(KontaktmanagementDetailsuche.SEARCH_KEY)
                            );
                            if (!this.detailsucheData) {
                                // Suchkriterien wurden im Storage nicht gefunden und die Details einer Suche werden gesucht
                                const gespeicherteSuche: KontaktmanagementClient.GespeicherteSucheResponse =
                                    this.gespeicherteSuchen.find(
                                        (suche: KontaktmanagementClient.GespeicherteSucheResponse) =>
                                            suche.suche.id === params.get(KontaktmanagementDetailsuche.SEARCH_KEY)
                                    );

                                if (gespeicherteSuche) {
                                    this.detailsucheData = gespeicherteSuche.suche;
                                    this.selectedSearch = gespeicherteSuche;
                                    this.changedSearchData = gespeicherteSuche;
                                    this.initDetailSearch(this.detailsucheData.suchkriterien);
                                } else {
                                    // Wennn die Suche nicht gefunden wurde, werden neue Suchen abgefragt, da mit dem Benutzer in der Zwischenzeit Suchen geteilt werden könnten
                                    this.getSuchen();
                                }
                            } else if (this.detailsucheData?.suchkriterien) {
                                this.initDetailSearch(this.detailsucheData.suchkriterien, false);
                                if (this.savedUserSearchCritera) {
                                    this.additionalSearchCriteria = this.savedUserSearchCritera;
                                }
                            }
                            // Wenn die Parameter den Gruppe Key beinhalten, wurde nach Kontakten von einer Gruppe gesucht
                        } else if (params.has(KontaktmanagementDetailsuche.GRUPPE_KEY)) {
                            const gruppe: KontaktmanagementClient.GruppeResponse = this.gruppen.find(
                                (gruppe: KontaktmanagementClient.GruppeResponse) =>
                                    gruppe.daten.id === params.get(KontaktmanagementDetailsuche.GRUPPE_KEY)
                            );
                            if (gruppe) {
                                const daten: DetailsuchkriterienDaten = {
                                    gruppeId: gruppe.daten.id
                                };
                                this.initDetailSearch(daten);
                            } else {
                                this.getGruppen();
                            }
                        } else {
                            if (this.savedUserSearchCritera?.length > 0) {
                                this.additionalSearchCriteria = this.savedUserSearchCritera;
                            }
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorResponse(error, 'Fehler bei der Abfrage der Capabilities');
                }
            });
    }

    /**
     * Setzt die Darstellung von Suchkriterien anhand der Capabilities
     * @param generalCapabilities Capabilities
     * @param searchCriteriaList Suchkriterien
     */
    setSearchCriteriaWithCapabilities(
        generalCapabilities: GeneralCapabilities,
        searchCriteriaList: DetailsucheAdditionalSearchCriteria[]
    ) {
        this.setDisplayOfSearchCritera(
            searchCriteriaList,
            KontaktmanagementDetailsuche.NOTIZ,
            generalCapabilities.detailsuche.canSeeNotizCriteria
        );

        this.setDisplayOfSearchCritera(
            searchCriteriaList,
            KontaktmanagementDetailsuche.TAG,
            generalCapabilities.detailsuche.canSeeTagCriteria
        );

        this.setDisplayOfSearchCritera(
            searchCriteriaList,
            KontaktmanagementDetailsuche.GRUPPE,
            generalCapabilities.detailsuche.canSeeGruppeCriteria
        );
    }

    /**
     * Fügt einen Tag zur Chip Liste hinzu
     * @param event MatChipInputEvent
     */
    addTagToChipList(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add Tag
        if (value && !this.tags.find((tag: string) => tag?.toLowerCase() === value?.toLowerCase())) {
            this.tags.push(value);
            // Filtert das selektierte Tag aus dem Autocomplete
            this.allTagsAutocomplete = this.allTagsAutocomplete.filter(
                (tag) => tag.toLowerCase() !== value?.toLowerCase()
            );
        }

        // Clear the input value
        event.chipInput!.clear();

        this.searchCriteriaForm.controls.tag.setValue(null);
    }

    /**
     * Entfernt einen Tag von der Chip Liste
     * @param tag tag
     */
    removeTagFromChipList(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
            // Fügt das entfernte Tag wieder beim Autocomplete hinzu wenn dieses in der Gesamtliste vorhanden ist
            const selectedTag = this.allTags.find((selectedTag) => selectedTag?.toLowerCase() === tag?.toLowerCase());
            if (selectedTag) {
                this.allTagsAutocomplete.push(selectedTag);
            }
        }

        this.searchCriteriaForm.controls.tag.setValue(this.currentTagInput);
    }

    /**
     * Reagiert auf die Selektion aus den autocomplete Optionen
     * @param event MatAutocompleteSelectedEvent
     */
    selectedTag(event: MatAutocompleteSelectedEvent): void {
        if (!this.tags.find((tag: string) => tag?.toLowerCase() === event.option.viewValue?.toLowerCase())) {
            this.tags.push(event.option.viewValue);
            // Filtert das selektierte Tag aus dem Autocomplete
            this.allTagsAutocomplete = this.allTagsAutocomplete.filter(
                (tag) => tag.toLowerCase() !== event.option.viewValue?.toLowerCase()
            );
        }

        this.tagInput.nativeElement.value = '';
        this.searchCriteriaForm.controls.tag.setValue(null);
    }

    /**
     * Filtert die Tag Liste nach Eingabe
     * @param value Eingabe
     * @returns gefilterte Liste
     */
    filterTagList(value: string): string[] {
        const filterValue = value?.toLowerCase();

        return this.allTagsAutocomplete.filter((tag) => tag?.toLowerCase().includes(filterValue)) ?? [];
    }

    /**
     * Fragt die Liste aller Suchen ab und initialisiert die Detailsuche
     */
    getSuchen(): void {
        this.kontaktmanagementService.getSuchen().subscribe({
            next: (gespeicherteSuchen: KontaktmanagementClient.GespeicherteSuchenResponse) => {
                if (gespeicherteSuchen) {
                    this.gespeicherteSuchen = gespeicherteSuchen.responses;
                }
                const gespeicherteSuche: KontaktmanagementClient.GespeicherteSucheResponse =
                    this.gespeicherteSuchen.find(
                        (suche: KontaktmanagementClient.GespeicherteSucheResponse) =>
                            suche.suche.id === this.currentParams.get(KontaktmanagementDetailsuche.SEARCH_KEY)
                    );
                if (gespeicherteSuche) {
                    this.detailsucheData = gespeicherteSuche.suche;
                    this.selectedSearch = gespeicherteSuche;
                    this.changedSearchData = gespeicherteSuche;
                    this.initDetailSearch(gespeicherteSuche.suche.suchkriterien);
                }
            }
        });
    }

    /**
     * Fragt die Liste aller Suchen ab und initialisiert die Detailsuche
     */
    getGruppen(): void {
        this.gruppenService.getGruppen().subscribe({
            next: (gruppen: KontaktmanagementClient.GruppeResponse[]) => {
                if (gruppen) {
                    this.gruppen = gruppen;
                }
                const daten: DetailsuchkriterienDaten = {
                    gruppeId: this.currentParams.get(KontaktmanagementDetailsuche.GRUPPE_KEY)
                };
                this.initDetailSearch(daten);
            }
        });
    }
    /**
     * Gibt an, ob der Bereich der gespeicherten Suchen angezeigt werden darf
     * @returns Bereich für die gespeicherten Suchen darf angezeigt werden
     */
    showGespeicherteSuchen(): boolean {
        if (this.generalCapabilities) {
            return (
                this.generalCapabilities.gespeicherteSuche.canCreate &&
                this.generalCapabilities.gespeicherteSuche.canRead
            );
        }
        return false;
    }

    /**
     * Initialisiert die Detailsuche
     * @param list Suchdetails
     * @param updateSearchCriteria Gibt an, ob die optionalen Suchkriterien anhand der Daten angezeigt werden sollen
     */
    initDetailSearch(list: DetailsuchkriterienDaten, updateSearchCriteria: boolean = true): void {
        if (list && !list?.suchstring) {
            if (updateSearchCriteria) {
                this.updateOptionalSearchCriteriaSelection(list);
            }

            let registergebunden: JaNeinEnum;
            if (list.istRegistergebunden === null) {
                registergebunden = JaNeinEnum.Alle;
            }
            if (list.istRegistergebunden === true) {
                registergebunden = JaNeinEnum.Ja;
            }
            if (list.istRegistergebunden === false) {
                registergebunden = JaNeinEnum.Nein;
            }
            let art: Personenart = list.art;
            if (list.art === null || list.art === undefined) {
                art = Personenart.Alle;
            }
            this.searchCriteriaForm.patchValue({
                art: art,
                name: list?.name,
                geburtsdatum: list?.geburtsdatum,
                erreichbarkeit: list?.erreichbarkeit,
                strasse: list?.strasse,
                orientierungsnummer: list?.orientierungsnummer,
                ort: list?.ort,
                istRegistergebunden: registergebunden,
                notiz: list?.notiz,
                firmenbuchNr: list?.firmenbuchNr,
                gruppe: list?.gruppeId,
                uidNummer: list?.uidNummer,
                ansprFunktion: list?.ansprFunktion,
                postleitzahl: list?.postleitzahl,
                gemeindekennziffer: list?.gemeindekennziffer,
                staatISO3: list?.staatISO3
            });
            this.tags = list?.tag?.split(',') ?? [];
            if (this.tags) {
                for (const tag of this.tags) {
                    // Filtert die bereits selektierten Tags aus dem Autocomplete
                    this.allTagsAutocomplete = this.allTagsAutocomplete.filter(
                        (selectedTag) => selectedTag?.toLowerCase() !== tag?.toLowerCase()
                    );
                }
                this.searchCriteriaForm.controls.tag.setValue(null);
            }
        } else {
            this.resetDetailSearch();
        }
    }

    /**
     * Setzt die Soriterung, die Spalten und Suchkriterien zusammen, speichert diese im Storage und navigiert mit query params weiter
     */
    handleSuchparameterAndNavigate(sorting?: string, columns?: string[]): void {
        this.schnellsucheService.emitClearSearchInput();

        // Wenn weder columns noch sorting gesetzt ist, wird die Suche manuell ausgeführt und die selekierte Suche wird zurückgesetzt
        if (!columns && !sorting) {
            this.selectedSearch = null;
            this.changedSearchData = null;
        }

        if (this.searchCriteriaForm.valid) {
            const query: UIDetailsucheRequest = { suchkriterien: {} };

            if (this.searchCriteriaForm.value.istRegistergebunden === JaNeinEnum.Alle) {
                query.suchkriterien.istRegistergebunden = null;
            } else if (this.searchCriteriaForm.value.istRegistergebunden === JaNeinEnum.Ja) {
                query.suchkriterien.istRegistergebunden = true;
            } else if (this.searchCriteriaForm.value.istRegistergebunden === JaNeinEnum.Nein) {
                query.suchkriterien.istRegistergebunden = false;
            }

            query.suchkriterien.ansprFunktion = this.searchCriteriaForm.value.ansprFunktion;
            query.suchkriterien.art =
                this.searchCriteriaForm.value.art === Personenart.Alle ? null : this.searchCriteriaForm.value.art;

            query.suchkriterien.erreichbarkeit = this.searchCriteriaForm.value.erreichbarkeit;
            query.suchkriterien.firmenbuchNr = this.searchCriteriaForm.value.firmenbuchNr;
            query.suchkriterien.geburtsdatum = this.searchCriteriaForm.value.geburtsdatum;
            query.suchkriterien.gemeindekennziffer = this.searchCriteriaForm.value.gemeindekennziffer;
            query.suchkriterien.name = this.searchCriteriaForm.value.name;
            query.suchkriterien.notiz = this.searchCriteriaForm.value.notiz;
            query.suchkriterien.orientierungsnummer = this.searchCriteriaForm.value.orientierungsnummer;
            query.suchkriterien.ort = this.searchCriteriaForm.value.ort;
            query.suchkriterien.postleitzahl = this.searchCriteriaForm.value.postleitzahl;
            query.suchkriterien.staatISO3 = this.searchCriteriaForm.value.staatISO3;
            query.suchkriterien.strasse = this.searchCriteriaForm.value.strasse;
            query.suchkriterien.tag = this.tags?.join(',');
            query.suchkriterien.uidNummer = this.searchCriteriaForm.value.uidNummer;
            query.suchkriterien.gruppeId = this.searchCriteriaForm.value.gruppe;

            query.pageSize = this.pageSize;
            query.pageNumber = 0;
            query.sortBy = null;

            // Die Suchkriterien loggen
            this.trackUseSuchkriterien(query.suchkriterien);

            // Ersetzt alle leeren Strings mit null
            Object.keys(query.suchkriterien).forEach(
                (i) => (query.suchkriterien[i] = query.suchkriterien[i] === '' ? null : query.suchkriterien[i])
            );

            if (query) {
                // Durchführen der Suche ist Aufgabe der Komponente für die Anzeige der Kontakte
                // Für die Suche werden die Parameter an die URL angefügt
                let guid: string;
                if (this.selectedSearch) {
                    guid = this.selectedSearch.suche.id;
                } else {
                    guid = this.detailSucheService.generateSessionStorageKey();
                    this.detailSucheService.saveSearchInStorage(query, guid);
                }
                this.router.navigate([], { relativeTo: this.route, queryParams: { searchKey: guid } });
            } else {
                this.router.navigate([], { relativeTo: this.route, queryParams: {} });
                this.alertService.info('Keine Suchparameter eingegeben.');
            }
        }
    }

    /**
     * Prüft alle Properties von Detailsuchkriterien und loggt ein Event, wenn diese Property bei der Detailsuche verwendet wurden.
     * Das Kriterium wird als Property auch mitgeloggt.
     * @param kriterien
     */
    trackUseSuchkriterien(suchkriterien: DetailsuchkriterienDaten): void {
        if (suchkriterien.ansprFunktion) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ANSPRFUNKTION
            });
        }

        if (suchkriterien.ansprName) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ANSPRNAME
            });
        }

        if (suchkriterien.art) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ART
            });
        }

        if (suchkriterien.erreichbarkeit) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ERREICHBARKEIT
            });
        }

        if (suchkriterien.firmenbuchNr) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.FIRMENBUCHNUMMER
            });
        }

        if (suchkriterien.geburtsdatum) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.GEBURTSDATUM
            });

            // Loggt die erweiterte Suchriterien vom Geburtsdatum Property
            this.trackUsedErweiterteSuchkriterium(suchkriterien.geburtsdatum);
        }

        if (suchkriterien.gemeindekennziffer) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.GEMEINDEKENNZIFFER
            });
        }

        if (suchkriterien.name) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.NAME
            });
        }

        if (suchkriterien.istRegistergebunden) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ISTREGISTERGEBUNDEN
            });
        }

        if (suchkriterien.notiz) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.NOTIZ
            });
        }

        if (suchkriterien.orientierungsnummer) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ORIENTIERUNGSNUMMER
            });
        }

        if (suchkriterien.ort) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ORT
            });
        }
    }

    /**
     * Loggt ein Event, wenn der User erweiterte Suchkriterien verwendet.
     * @param geburtsdatum Gebrutsdatum als String
     */
    trackUsedErweiterteSuchkriterium(geburtsdatum: string): void {
        // Alle Parameter durchiterieren
        for (let key in LoggingConstants.ERWEITERTE_SUCHPARAMETERN) {
            let value = LoggingConstants.ERWEITERTE_SUCHPARAMETERN[key];
            let anzahl = this.getUsedAnzahlvonParameterimGeburtsdatum(geburtsdatum, value);
            if (anzahl > 0) {
                this.loggingService.logEvent(LoggingConstants.KM_SUCHKRITERIUM_ERWEITERT_USED, { [value]: anzahl });
            }
        }
    }

    /**
     * Wenn das Geburtsdatum den Value enthält, wird die Anzahl der Fälle retourniert.
     * @param geburtsdatum
     * @param value
     * @returns
     */
    getUsedAnzahlvonParameterimGeburtsdatum(geburtsdatum: string, value: string): number {
        if (geburtsdatum.includes(value)) {
            return geburtsdatum.split(value).length - 1;
        }
        return 0;
    }

    /**
     * Öffnet den Dialog für das Teilen einer Suche
     * @param search ausgewählte Suche
     */
    shareSearch(search: KontaktmanagementClient.GespeicherteSucheResponse): void {
        this.kontaktmanagementService.getSucheInfo(search.suche.id).subscribe({
            next: (suche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                const userShareDialogRef = this.dialog.open<
                    UserShareDialogComponent,
                    UserShareDialogData,
                    UITeilenMitBenutzer[]
                >(UserShareDialogComponent, {
                    width: '25rem',
                    data: {
                        title: 'Gespeicherte Suche teilen',
                        allowMultipleSelection: true,
                        selectedUsers: suche.suche.geteiltMitBenutzern.map((benutzer) => benutzer.id),
                        saveDisabled: false
                    }
                });

                userShareDialogRef.componentInstance.saveSelection
                    .pipe(
                        switchMap(
                            (
                                userList: UITeilenMitBenutzer[]
                            ): Observable<KontaktmanagementClient.GespeicherteSucheResponse> => {
                                userShareDialogRef.componentInstance.saveButtonDisabled = true;
                                return this.kontaktmanagementService
                                    .shareSucheWithBenutzer(
                                        {
                                            benutzerIds: userList.map((user) => user.data.id)
                                        },
                                        suche.suche.id
                                    )
                                    .pipe(
                                        catchError((error: HttpErrorResponse) => {
                                            userShareDialogRef.componentInstance.saveButtonDisabled = false;
                                            this.alertService.errorResponse(error, 'Fehler beim Teilen einer Suche');
                                            return of(null);
                                        })
                                    );
                            }
                        )
                    )
                    .subscribe({
                        next: () => {
                            userShareDialogRef.componentInstance.saveButtonDisabled = false;
                            this.alertService.success('Suche erfolgreich geteilt');
                            userShareDialogRef.close();
                        }
                    });
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der Suche');
            }
        });
    }

    /**
     * Resettet die Detailsuche und die Schnellsuche
     */
    resetDetailSearchAndQuicksearch(): void {
        this.resetDetailSearch();
        this.schnellsucheService.emitClearSearchInput();
    }

    /**
     * Resettet die Detailsuche
     */
    resetDetailSearch(): void {
        const kriterien: DetailsuchkriterienDaten = {
            art: null,
            name: null,
            geburtsdatum: null,
            erreichbarkeit: null,
            strasse: null,
            orientierungsnummer: null,
            ort: null,
            istRegistergebunden: null,
            notiz: null,
            firmenbuchNr: null,
            uidNummer: null,
            ansprFunktion: null,
            tag: null,
            postleitzahl: null,
            gemeindekennziffer: null,
            staatISO3: null,
            gruppeId: null
        };
        this.tags = [];
        this.allTagsAutocomplete = this.allTags;

        this.initDetailSearch(kriterien, false);
        if (this.savedUserSearchCritera) {
            this.additionalSearchCriteria = this.savedUserSearchCritera;
        }
        this.changedSearchData = null;
        this.selectedSearch = null;
    }

    /**
     * Initialisiert die Detailsuche und führt die Detailsuche aus
     * @param search
     */
    handleSearchChanged(search: KontaktmanagementClient.GespeicherteSucheResponse): void {
        this.selectedSearch = search;

        this.initDetailSearch(search.suche.suchkriterien);

        let sorting: string;
        let columnsShown: string[] = [];

        for (let column of search.suche.spalten) {
            if (column.sortierung) {
                sorting = column.key + ' ' + column.sortierung;
            }
            columnsShown.push(column.key);
        }

        this.handleSuchparameterAndNavigate(sorting, columnsShown);
    }

    /**
     * Gibt die Sortierung für eine Spalte formatiert zurück
     * @param column
     */
    getSortingForColumn(column: string): string | null {
        if (!this.detailsucheData.sortBy) {
            return null;
        } else {
            if (this.detailsucheData.sortBy[0]?.split(' ')[0].toLowerCase() === column.toLowerCase()) {
                return this.detailsucheData.sortBy[0].split(' ')[1].toLowerCase();
            } else {
                return null;
            }
        }
    }

    /**
     * Speichert eine neue Suche
     * @param search Suche
     */
    saveNewSearch(search: KontaktmanagementClient.GespeicherteSucheDaten): void {
        if (search) {
            const request: CreateGespeicherteSucheRequest = this.createSearchRequest(search.beschreibung, search.name);

            this.dialog.closeAll();
            this.globalEditService.switchToReadMode();

            if (request.spalten && request.suchkriterien) {
                const startFrom: number = new Date().getTime();
                this.kontaktmanagementService.createSuche(request).subscribe({
                    next: (response: KontaktmanagementClient.GespeicherteSucheResponse) => {
                        if (response) {
                            this.loggingService.logMetricWithElapsedTime(
                                LoggingConstants.KM_DETAILSUCHE_SPEICHERN,
                                startFrom,
                                new Date().getTime()
                            );
                            // Loggt ein Event, wenn eine Suche gespeichert wird
                            this.loggingService.logEvent(LoggingConstants.KM_DETAILSUCHE_SPEICHERN);
                            this.gespeicherteSuchen.push(response);
                            this.alertService.success('Suche erfolgreich gespeichert');
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorResponse(error, 'Fehler beim Speichern einer Suche');
                    }
                });
            } else {
                this.alertService.warning('Bitte führen Sie vorher eine Suche aus');
            }
        }
    }

    /**
     * Setzt die Daten des Requests beim Speichern einer Suche
     * @param beschreibung Beschreibung der Suche
     * @param name Name der Suche
     * @returns Befüllter request
     */
    createSearchRequest(beschreibung: string, name: string): CreateGespeicherteSucheRequest {
        const request: CreateGespeicherteSucheRequest = {
            beschreibung: beschreibung,
            name: name
        };

        if (this.currentParams.has(KontaktmanagementDetailsuche.SEARCH_KEY)) {
            request.spalten = [];
            const cols = this.detailSucheService.getDisplayedColumns();

            this.detailsucheData = this.detailSucheService.getSearchFromStorage(
                this.currentParams.get(KontaktmanagementDetailsuche.SEARCH_KEY)
            );
            if (!this.detailsucheData) {
                this.detailsucheData = this.selectedSearch.suche;
            }

            this.detailsucheData.sortBy = [this.detailSucheService.getSorting()];
            request.suchkriterien = this.detailsucheData.suchkriterien;

            for (let column of cols) {
                request.spalten.push({
                    breite: null,
                    key: column,
                    sortierung: this.getSortingForColumn(column)
                });
            }
        }
        return request;
    }

    /**
     * Speichert eine vorhandene Suche
     */
    saveExistingSearch(search: KontaktmanagementClient.GespeicherteSucheDaten): void {
        if (search) {
            const request: ChangeGespeicherteSucheRequest = this.createSearchRequest(search.beschreibung, search.name);

            this.dialog.closeAll();
            this.globalEditService.switchToReadMode();
            if (request.spalten && request.suchkriterien) {
                this.kontaktmanagementService.updateSuche(request, search.id).subscribe({
                    next: (sucheDatenResponse: KontaktmanagementClient.GespeicherteSucheResponse) => {
                        if (sucheDatenResponse) {
                            const index = this.gespeicherteSuchen.findIndex(
                                (suche) => suche.suche.id === sucheDatenResponse.suche.id
                            );
                            this.gespeicherteSuchen[index] = sucheDatenResponse;
                            this.selectedSearch = sucheDatenResponse;
                            this.changedSearchData = sucheDatenResponse;
                            this.alertService.success('Suche erfolgreich gespeichert');
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorResponse(error, 'Fehler beim Speichern einer Suche');
                    }
                });
            } else {
                this.alertService.warning('Bitte führen Sie vorher eine Suche aus');
            }
        } else {
            this.alertService.info('Bitte vorher eine Suche auswählen');
        }
    }

    /**
     * Löscht eine gespeicherte Suche
     * @param search Suche
     */
    deleteSearch(search: KontaktmanagementClient.GespeicherteSucheResponse): void {
        this.kontaktmanagementService.deleteSuche(search.suche.id).subscribe({
            next: () => {
                this.dialog.closeAll();
                this.gespeicherteSuchen = this.gespeicherteSuchen.filter((suche) => suche.suche.id !== search.suche.id);
                this.alertService.success('Suche erfolgreich gelöscht');
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Löschen einer gespeicherten Suche');
            }
        });
    }

    /**
     * Gibt an, ob ein Form Field dargestellt werden soll
     * @param key Key des Form Fields
     * @returns
     */
    showFormField(key: string): boolean {
        const result: DetailsucheAdditionalSearchCriteria | undefined = this.additionalSearchCriteria.find(
            (criteria) => criteria.key === key
        );
        return result ? result.selected && result.display : false;
    }

    /**
     * Horcht auf die Input Felder der Detailsuche.
     * Wenn in ein Inputfeld geschrieben wird, soll der Suchparameter in der Schnellsuchleiste gelöscht werden.
     * Die Ergebnisliste soll aber erhalten bleiben.
     */
    @HostListener('oninput')
    clearQuickSearchPara(): void {
        this.schnellsucheService.emitClearSearchInput();
    }

    /**
     * Behandelt die Änderung der Suchkriterienselektion und
     * aktualisiert die Auswahl der Suchkriterien
     * @param selection DetailsucheAdditionalSearchCriteria
     */
    searchCriteriaSelectionChange(selection: DetailsucheAdditionalSearchCriteria[]): void {
        const additionalSearchCriterias: DetailsucheAdditionalSearchCriteria[] = JSON.parse(
            JSON.stringify(this.additionalSearchCriteria)
        );
        const selectionKeys = selection.map((selection) => selection.key);
        for (let i = 0; i < additionalSearchCriterias.length; i++) {
            const additionalSearchCriteria = additionalSearchCriterias[i];
            additionalSearchCriteria.selected = selectionKeys.includes(additionalSearchCriteria.key) ? true : false;
        }
        this.additionalSearchCriteria = JSON.parse(JSON.stringify(additionalSearchCriterias));
        this.savedUserSearchCritera = JSON.parse(JSON.stringify(additionalSearchCriterias));
        this.userInformationService
            .saveBenutzereinstellung(additionalSearchCriterias, UiConstants.KONTAKT_SEARCH_CRITERIA)
            .subscribe();
    }

    /**
     * Setzt Sichtbarkeit der weiteren Suchkriterien
     * @param criteriaKey Key für die Kriterien
     */
    updateOptionalSearchCriteriaSelection(list: DetailsuchkriterienDaten): void {
        const updatedAdditionalSearchCritera = JSON.parse(JSON.stringify(this.additionalSearchCriteria));
        this.updateControl(
            list.istRegistergebunden,
            KontaktmanagementDetailsuche.IST_REGISTERGEBUNDEN,
            this.searchCriteriaForm.controls.istRegistergebunden,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.notiz,
            KontaktmanagementDetailsuche.NOTIZ,
            this.searchCriteriaForm.controls.notiz,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.firmenbuchNr,
            KontaktmanagementDetailsuche.FIRMENBUCH_NR,
            this.searchCriteriaForm.controls.firmenbuchNr,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.uidNummer,
            KontaktmanagementDetailsuche.UID_NUMMER,
            this.searchCriteriaForm.controls.uidNummer,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.postleitzahl,
            KontaktmanagementDetailsuche.POSTLEITZAHL,
            this.searchCriteriaForm.controls.postleitzahl,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.gemeindekennziffer,
            KontaktmanagementDetailsuche.GKZ,
            this.searchCriteriaForm.controls.gemeindekennziffer,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.staatISO3,
            KontaktmanagementDetailsuche.STAAT,
            this.searchCriteriaForm.controls.staatISO3,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.ansprFunktion,
            KontaktmanagementDetailsuche.ANSPRECHPARTNER_FUNKTION,
            this.searchCriteriaForm.controls.ansprFunktion,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.gruppeId,
            KontaktmanagementDetailsuche.GRUPPE,
            this.searchCriteriaForm.controls.gruppe,
            updatedAdditionalSearchCritera
        );
        this.updateControl(
            list.tag,
            KontaktmanagementDetailsuche.TAG,
            this.searchCriteriaForm.controls.tag,
            updatedAdditionalSearchCritera
        );
        this.additionalSearchCriteria = JSON.parse(JSON.stringify(updatedAdditionalSearchCritera));
    }

    /**
     * Updated die additional search criteria
     * @param listItem Feld in der Detailsuche
     * @param detailsucheControlName Name des Detailsucheparameters
     * @param control FormControl
     * @param additionalSearchCritera Suchkriterien Liste
     */
    updateControl(
        listItem: any,
        detailsucheControlName: string,
        control: FormControl,
        additionalSearchCritera: DetailsucheAdditionalSearchCriteria[]
    ): void {
        const searchCriteriaIndex = additionalSearchCritera.findIndex((x) => x.key === detailsucheControlName);
        if (listItem === null || listItem === undefined) {
            additionalSearchCritera[searchCriteriaIndex].selected = false;
            control.setValue(null);
        } else {
            additionalSearchCritera[searchCriteriaIndex].selected = true;
        }
    }
}
