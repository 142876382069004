// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlingConstants } from '@core/constants/error-handling-constants';
import { Problem } from '@core/models/problem';
import { ProblemResponse } from '@core/models/problemResponse';

/**
 * Service für die Generierung von ProblemResponses
 */
@Injectable({
    providedIn: 'root'
})
export class ProblemResponseService {
    /**
     * Extrahiert die Fehlerinformation
     * @param response HttpErrorResponse
     */
    async parseErrorReponse(response: HttpErrorResponse): Promise<Problem> {
        let problem: Problem;
        if (response.error instanceof ErrorEvent) {
            // handle client-side errors
            problem = {
                type: ErrorHandlingConstants.GENERIC_PROBLEM_TYPE,
                caseId: null,
                instance: null,
                statusCode: response.status,
                title: ErrorHandlingConstants.DEFAULT_ERROR_MESSAGE,
                detail: response.error?.message ? response.error.message : ErrorHandlingConstants.RETRY_MESSAGE,
                detailValues: []
            };
        } else {
            // handle server-side errors
            problem = await this.extractProblemResponse(response);
        }
        return problem;
    }

    /**
     * Generiert einen Fehlertext entsprechend dem zurückgegebenen Http-Statuscode
     * @param status Http-Statuscode
     * @returns Fehlertext zur Anzeige in den UI-Details
     */
    getErrorMessage(status: number): string {
        switch (status) {
            case 404: {
                return ErrorHandlingConstants.RESOURCE_NOT_FOUND;
            }
            case 403: {
                return ErrorHandlingConstants.FORBIDDEN_ERROR;
            }
            case 500: {
                return ErrorHandlingConstants.INTERNAL_SERVER_ERROR;
            }
            default: {
                return ErrorHandlingConstants.SERVER_UNAVAILABLE_ERROR;
            }
        }
    }

    /**
     * Extrahiert die Informationen aus den verschiedenen ProblemResponses
     * @param response HttpErrorResponse nach dem Aufruf eines Web-Services
     */
    private async extractProblemResponse(response: HttpErrorResponse): Promise<Problem> {
        let problem: Problem;

        // error-object should be of type ProblemResponse
        if (Object.hasOwn(response.error, 'type')) {
            const problemResponse: ProblemResponse = response.error;
            problem = this.mapProblemResponse(problemResponse, response);
        }
        // if documents are downloaded then the error message is sent as blob
        else if (response.error instanceof Blob) {
            const problemResponse: ProblemResponse = JSON.parse(await response.error.text());
            problem = this.mapProblemResponse(problemResponse, response);
        } else {
            problem = {
                type: ErrorHandlingConstants.GENERIC_PROBLEM_TYPE,
                caseId: null,
                instance: null,
                statusCode: response.status,
                title: ErrorHandlingConstants.DEFAULT_ERROR_MESSAGE,
                detail: this.getErrorMessage(response.status),
                detailValues: []
            };
        }

        return problem;
    }

    /**
     * Mapped einen ProblemResponse auf ein generisches Datenmodell zur Fehleranzeige im UI
     * @param problemResponse Extrahierter ProblemResponse
     * @param response Ursprünglicher HttpErrorResponse
     * @returns Gemapptes Problem für die Anzeige im UI
     */
    private mapProblemResponse(problemResponse: ProblemResponse, response: HttpErrorResponse): Problem {
        const problem: Problem = {
            type: problemResponse.type,
            caseId: problemResponse.caseId,
            instance: problemResponse.instance,
            statusCode: response.status,
            title: problemResponse.title,
            detail: problemResponse.detail,
            detailValues: []
        };

        if (problemResponse.detailValues) {
            /**
             * extrahiert die Detailinformationen je Fehlertyp
             * Dokumentation: https://gemeindeapplikationen.atlassian.net/l/c/qBX8pJgQ
             */
            switch (problemResponse.type) {
                case ErrorHandlingConstants.BUSINESS_PROBLEM_TYPE:
                    problem.detailValues = problemResponse.detailValues.messages;
                    break;
                case ErrorHandlingConstants.SECURITY_PROBLEM_TYPE:
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.user,
                        'Benutzer:'
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.additionalInformation
                    );
                    break;
                case ErrorHandlingConstants.SUBSYSTEM_PROBLEM_TYPE:
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.id,
                        'Fehlercode:'
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.title
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.detail
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.additionalInformation
                    );
                    break;
                case ErrorHandlingConstants.TECHNICAL_PROBLEM_TYPE:
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.source,
                        'Technische Komponente:'
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.id,
                        'Fehlercode:'
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.title
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.detail
                    );
                    problem.detailValues = this.addDetailValue(
                        problem.detailValues,
                        problemResponse.detailValues.additionalInformation
                    );
                    break;
                default:
                    break;
            }
        }
        return problem;
    }

    /**
     * Fügt einen Wert zu einem Array hinzu und verändert dabei das Ursprungsarray nicht (immutable).
     * @param value Wert zum hinzufügen
     * @param prefix Optionaler Präfix
     */
    private addDetailValue(detailValues: string[], value: string, prefix: string = ''): string[] {
        if (value) {
            return [...detailValues, `${prefix} ${value}`];
        }
        return detailValues;
    }
}
