// @ts-strict-ignore
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { Staatsangehoerigkeiten } from '@shared/constants/shared-constants';
import { AdressenClient } from '@shared/models/adressenClient';
import { FileInfo } from '@shared/models/fileInfo';
import { SprengelgruppeListeResponse } from '@shared/models/sprengelgruppeListeResponse';
import { SprengelgruppeartEnum } from '@shared/models/sprengelgruppeartEnum';
import { StaatenResponse } from '@shared/models/staatenResponse';
import { StrasseResponse } from '@shared/models/strasseResponse';
import { UISprengellokalListeResponse } from '@shared/models/uISprengellokalListeResponse';
import { UISprengellokalResponse } from '@shared/models/uISprengellokalResponse';
import { WahlberechtigtePersonAdresse } from '@shared/models/wahlberechtigtePersonAdresse';
import { Zustelladresse } from '@shared/models/zustelladresse';
import { Observable } from 'rxjs';
import { WahlsprengelWahlartEnum } from 'src/app/buergerservice/configuration/models/wahlsprengelWahlartEnum';
import { WaehlerDaten } from 'src/app/buergerservice/wahl/models/waehlerDaten';

@Injectable({
    providedIn: 'root'
})
export class AdressenService {
    config: AppConfig = null;
    wahllokalPath: string;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {
        this.config = this.configService.getConfig();
        this.wahllokalPath = `${this.configService.getConfig().k5civisAdr.adressenApiUrl}/Adressen/Sprengellokale`;
    }

    /**
     * Liefert alle aktiven Staaten
     * @returns Observable<StaatenResponse>
     */
    getStaaten(): Observable<StaatenResponse> {
        return this.httpClient.get<StaatenResponse>(`${this.config.k5civisAdr.adressenUiApiUrl}/adressen/Staaten`, {
            params: {
                nurAktiveStaaten: true,
                nurAuswaehlbare: true,
                nurAnerkannteStaaten: true
            }
        });
    }

    /**
     * Liefert alle Straßen, die im Namen den Suchstring enthalten
     * @param suchstring Suchstring des Straßenamens
     * @returns Observable<Strasse[]>
     */
    getStrassen(suchstring: string): Observable<StrasseResponse> {
        return this.httpClient.get<StrasseResponse>(
            `${this.config.k5civisAdr.adressenUiApiUrl}/adressen/Strassen?SuchString=${suchstring}`
        );
    }

    /**
     * Updated die Zustelladresse eines Wählers
     * @param wahlbestandId Id des Wählers
     * @param zustelladresse Zustelladresse
     * @returns Observable<Waehler> neuer Wähler
     */
    updateWahlbestandZustelladresse(wahlbestandId: string, zustelladresse: Zustelladresse): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.config.k5civisWv.wahlvorbereitungUiApiUrl}/Waehler/${wahlbestandId}/Zustelladresse`,
            zustelladresse,
            { headers }
        );
    }

    /**
     * Abfrage aller Sprengelgruppen einer Sprengelart
     * @param byArt Art der Sprengelgruppe
     */
    getSprengelgruppen(byArt: SprengelgruppeartEnum): Observable<SprengelgruppeListeResponse> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams().append('art', byArt);
        return this.httpClient.get<SprengelgruppeListeResponse>(
            `${this.configService.getConfig().k5civisAdr.adressenApiUrl}/adressen/Sprengelgruppen/ByArt`,
            { headers, params }
        );
    }

    /**
     * Extrahiert die Zustelladresse eines Wählers
     */
    getZustelladresseFromWaehler(waehler: WaehlerDaten): Zustelladresse {
        const zustelladresse: Zustelladresse = {
            zA_Staat: waehler?.zA_Staat,
            zA_StaatText: waehler?.zA_StaatText,
            zA_Anschriftszeile1: waehler?.zA_Anschriftszeile1,
            zA_Anschriftszeile2: waehler?.zA_Anschriftszeile2,
            zA_Anschriftszeile3: waehler?.zA_Anschriftszeile3,
            zA_Anschriftszeile4: waehler?.zA_Anschriftszeile4,
            zA_Anschriftszeile5: waehler?.zA_Anschriftszeile5,
            zA_Anschriftszeile6: waehler?.zA_Anschriftszeile6
        };
        return zustelladresse;
    }

    /**
     * Gibt die formattierte Zustelladresse eines Wählers zurück
     * @param waehler Wählerdaten
     * @returns Formattierte Zustelladresse oder einen Leerstring, wenn die Zustelladresse ungültig ist
     */
    getZustelladresseFormatted(waehler: WaehlerDaten): string {
        return this.formatZustelladresse(this.getZustelladresseFromWaehler(waehler));
    }

    /**
     * Formattiert die Zustelladresse und gibt diese in Textform zurück
     * @param zustelladresse Zustelladresse
     * @returns Formattierte Zustelladresse
     */
    formatZustelladresse(zustelladresse: Zustelladresse): string {
        const zustelladresseText: string = '';

        if (zustelladresse) {
            const adressParts: string[] = [
                zustelladresse.zA_Anschriftszeile1,
                zustelladresse.zA_Anschriftszeile2,
                zustelladresse.zA_Anschriftszeile3,
                zustelladresse.zA_Anschriftszeile4,
                zustelladresse.zA_Anschriftszeile5,
                zustelladresse.zA_Anschriftszeile6
            ];
            if (zustelladresse.zA_Staat !== Staatsangehoerigkeiten.OESTERREICH_ISO3) {
                adressParts.push(zustelladresse.zA_StaatText);
            }

            return adressParts.filter((anschriftsZeile) => anschriftsZeile).join(', ');
        }

        // ist die Zustelladresse ungültig, so wird ein Leerstring zurückgegeben
        return zustelladresseText;
    }

    /**
     * Formattiert die Wahlbezugsadresse eines Wählers
     * @param waehler Wählerdaten
     * @returns Formattierte Wahlbezugsadresse
     */
    getWahlbezugsadresseFormatted(waehler: WaehlerDaten): string {
        if (!waehler) return '';

        const part1: string = [waehler.wA_Strasse, waehler.wA_Hausnummer, waehler.wA_Stiege, waehler.wA_Tuer]
            .filter((part) => part)
            .join(' ');
        const part2: string = [waehler.wA_Plz, waehler.wA_Ort].filter((part) => part).join(' ');
        return [part1, part2].join(', ');
    }

    /**
     * Formattiert die Adresse zur Anzeige im UI
     * @param adresse Adresse
     * @returns Formattierte Adresse
     */
    getAdresseFormatted(adresse: WahlberechtigtePersonAdresse): string {
        if (!adresse) return '';

        const part1: string = [adresse.strassenname, adresse.orientierungsnummer].filter((part) => part).join(' ');
        const part2: string = [part1, adresse.stiege, adresse.nutzungseinheit].filter((part) => part).join(' / ');
        const part3: string = [adresse.postleitzahl, adresse.ortschaft].filter((part) => part).join(' ');
        return [part2, part3].join(', ');
    }

    /**
     * Abfrage der Sprengelgruppe mit Sprengel
     * @param sprengelgruppeId Id der Sprengelgruppe
     */
    getSprengelgruppeWithSprengel(sprengelgruppeId: string): Observable<AdressenClient.SprengelgruppeResponse> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.get<AdressenClient.SprengelgruppeResponse>(
            `${this.configService.getConfig().k5civisAdr.adressenApiUrl}/Adressen/Sprengelgruppen/${sprengelgruppeId}`,
            { headers }
        );
    }

    /**
     * Abfrage der Wahllokale
     * @param sprengelgruppeId Id der Sprengelgruppe
     */
    getWahllokale(sprengelgruppeId: string): Observable<UISprengellokalListeResponse> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let params: HttpParams = new HttpParams();
        params = params.append('sprengelgruppeId', sprengelgruppeId);
        return this.httpClient.get<UISprengellokalListeResponse>(this.wahllokalPath, {
            headers: headers,
            params: params
        });
    }

    /**
     * Bearbeitung eines Wahllokals
     */
    updateWahllokal(wahllokal: AdressenClient.UpdateSprengellokalRequest): Observable<UISprengellokalResponse> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.put<UISprengellokalResponse>(this.wahllokalPath, wahllokal, { headers });
    }

    /**
     * Überprüft ob der erhalten Response im Header einen Dateinamen und einen Dateityp enthält und gibt diese zurück
     * @param dataReceived Response inkl. Header
     * @returns Ein Objekt des Typs FileInfo mit Dateinamen und Endung
     */
    getFileNameAndExtensionFromResponse(dataReceived: HttpResponse<Blob>): FileInfo {
        const fileInfo: FileInfo = { name: '', extension: '' };

        const dataFromHeader: string = dataReceived.headers.get('X-Export-File-Name');

        if (dataFromHeader && dataFromHeader.split('.').length > 1) {
            fileInfo.name = dataFromHeader.split('.')[0];

            fileInfo.extension = dataFromHeader.split('.')[1];
        } else {
            fileInfo.name = dataFromHeader;

            fileInfo.extension = '';
        }

        return fileInfo;
    }

    /**
     * Triggert den AGWR Abgleich
     */
    triggerAGWRAbgleich(): Observable<string> {
        return this.httpClient.get<string>(
            `${this.config.k5civisAdr.adressenUiApiUrl}/Adressen/AdressenImport/AutoImport`
        );
    }

    /**
     * Download aller Sprengel incl. Sprengelzuordnungsregeln und Wahhlokale einer angegebenen Sprengelgruppe als CSV
     * @param sprengelgruppeId Id der Sprengelgruppe
     * @returns CSV mit allen Sprengeln incl. Sprengelzuordnungsregeln und Wahhlokalen
     */
    sprengelExportAsCsv(sprengelgruppeId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(
            `${this.config.k5civisAdr.adressenUiApiUrl}/adressen/SprengelExport/csv?sprengelgruppeId=${sprengelgruppeId}`,
            {
                headers: new HttpHeaders({ Accept: 'text/csv' }),
                observe: 'response',
                responseType: 'blob'
            }
        );
    }

    /**
     * Download aller Sprengel einer ausgewählten Sprengelgruppe mit den zugehörigen Wahllokalen für eine bestimmte Wahl als CSV
     * @param sprengelgruppeId Id der Sprengelgruppe
     * @param wahlArtEnum Nummerischer Enum-Wert der Wahl
     * @returns CSV mit allen Sprengeln und Wahllokalen für eine bestimmte Wahl
     */
    sprengelMitWahllokalenExportAsCsv(
        sprengelgruppeId: string,
        wahlArtEnum: WahlsprengelWahlartEnum
    ): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(
            `${this.config.k5civisAdr.adressenUiApiUrl}/adressen/SprengelExport/Sprengelgruppe/${sprengelgruppeId}/Wahlsprengel/Export?wahlTyp=${wahlArtEnum}`,
            {
                headers: new HttpHeaders({ Accept: 'text/csv' }),
                observe: 'response',
                responseType: 'blob'
            }
        );
    }

    /**
     * Download aller Sprengel einer ausgewählten Sprengelgruppe mit den entsprechenden Objektzuordnungen für eine bestimmte Wahl als CSV
     * @param sprengelgruppeId Id der Sprengelgruppe
     * @param wahlArtEnum Nummerischer Enum-Wert der Wahl
     * @returns CSV mit allen Sprengeln und Objektzuordnungen für eine bestimmte Wahl
     */
    sprengelMitObjektzuordnungenExportAsCsv(
        sprengelgruppeId: string,
        wahlArtEnum: WahlsprengelWahlartEnum
    ): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(
            `${this.config.k5civisAdr.adressenUiApiUrl}/adressen/SprengelExport/Sprengelgruppe/${sprengelgruppeId}/Objektzuordnungen/Export?wahlTyp=${wahlArtEnum}`,
            {
                headers: new HttpHeaders({ Accept: 'text/csv' }),
                observe: 'response',
                responseType: 'blob'
            }
        );
    }

    /**
     * Abfrage der Sprengel einer Sprengelgruppe
     * @param sprengelgruppeId Id der Sprengelgruppe
     * @returns SprengelListeResponse as Observable
     */
    getSprengelByGruppeId(sprengelgruppeId: string): Observable<AdressenClient.SprengelListeResponse> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let params: HttpParams = new HttpParams();
        params = params.append('sprengelgruppeId', sprengelgruppeId);

        return this.httpClient.get<AdressenClient.SprengelListeResponse>(
            `${this.config.k5civisAdr.adressenApiUrl}/adressen/Sprengel`,
            {
                headers: headers,
                params: params
            }
        );
    }
}
